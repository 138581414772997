<template>
  <div class="sonContent">
    <div class="SearchBar">
      <div style="display: flex">
        <span style="margin: 6px 10px 0 10px ">工程编号/名称:</span>
        <el-input style="width: 200px" placeholder="请输入" size="small"  v-model="searchBox.keyWord"></el-input>
        <span style="margin: 6px 10px 0 10px ">工程类型:</span>
        <el-select v-model="searchBox.timeType" style="width:200px;" placeholder="请选择" size="small">
          <el-option
              v-for="item in tuiliaoList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <span style="margin: 6px 10px 0 10px ">第三方施工队：</span>
        <el-input style="width: 200px" placeholder="请输入" size="small"  v-model="searchBox.keyWord"></el-input>
        <el-button type="primary" class="SearchBtn" size="small" @click="search" style="margin-left: 10px">查询</el-button>
      </div>

    </div>

    <!--数据表格-->
    <el-table
        ref="tableData"
        :data="tableData"
        style="width: 100%;margin: 15px 0;"
        border
        :max-height="530"
        :stripe="true">
      <el-table-column type="index" label="序号" width="70" align="center"> </el-table-column>
      <el-table-column prop="projectNumber" label="工程编号" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="projectName" label="工程名称" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="constructionTeam" label="第三方施工队" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="siteManager" label="现场管理员" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <el-button  @click="showDetil(row)" type="success" size="mini">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageBox">
      <el-pagination
          :current-page="searchBox.current"
          :background="true"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="searchBox.size"
          layout="total, prev, pager, next, sizes, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
        title="审核详情"
        :visible.sync="detildialogVisible"
        width="30%">
      <div style="max-height: 600px;overflow: auto">
        <p class="detil-code">
          申请信息
        </p>
        <div style="padding: 10px">
          <div style="display: flex;line-height: 50px">
            <div style="flex: 1;">工程名称：{{project.name}}</div>
            <div style="flex: 1;">工程类型：{{project.name}}</div>
            <div style="flex: 1;">现场管理员：{{project.name}}</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="detildialogVisible = false">关 闭</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  archiveRecords,
} from "../../../RequestPort/menu/menu";
export default {
  name: "dataTabel",
  props:{
    hanleBtn:{
      type:[Number,String],
      default:null
    }
  },
  data(){
    return{
      detildialogVisible:false,
      searchBox:{
        current:1,
        size: 100,
        timeType:1
      },
      total:null,
      tableData:[{}],
      project:{},

      tuiliaoList:[
        {label:'未退料',value:1},
        {label:'退料中',value:2},
        {label:'退料完成',value:3},
      ],
    }
  },
  mounted() {
    if(this.hanleBtn==1){//

    }else{
      this.loadList(this.searchBox)
    }

  },
  methods:{
    showDetil(row){
      this.detildialogVisible = true

    },
    loadList(obj){
      archiveRecords(obj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val){
      this.searchBox.current = 1
      this.searchBox.size = val
      this.loadList(this.searchBox)
    },
    handleCurrentChange(val){
      this.searchBox.current = val
      this.loadList(this.searchBox)
    },
    search(){
      this.loadList(this.searchBox)
    },
  }
}
</script>

<style scoped>
.SearchBar {
  border: 1px solid #ecedf1;
  border-radius: 5px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.detil-code{
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before{
  content: '';
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007AFF;
  top: 0;
  left: 0;
}
</style>